/* Insufficient contrast against white - may be reasonable when used as background colour against black */
/* Accessible colours with good contrast (at least against white) */
#comments-box #comment-text {
  border: 1px solid #ccc;
}
#comments-box #comments .comment-container .comment-content .edit-comment .edit-comment-controls div:before {
  margin-left: 3px;
}
#comments-box #comments textarea {
  border: 1px solid #ccc;
}
#comments-box #comments textarea:focus {
  border: 1px solid #66afe9;
}
